import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../smartDongles/views/components";
import {SolInput} from "../../../components/SolStyledComponents/components";
import {DataTableContainer} from "../../rentLog/utils";
import {LoadingSpinner} from '../../../components/LoadingSpinnerForDataTable';
import DataTable from "../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {appEventActions} from "../../appEvents/actions";
import {actions} from "../actions";
import DateTimeFormat from "../../../utils/dateFormat/DateTimeFormat";
import {Badge, Button, Form, Spinner} from "react-bootstrap";
import FilterListIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/styles";
import Select from "react-select";
import {useSubheader} from "../../../../_metronic/layout";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const FirmwareUpdateHistory = props => {
    const subHeader = useSubheader()
    const [language, setLanguage] = useState('EN')
    const [serialNumberIsInvalid, setSerialNumberIsInvalid] = useState(false)
    const [showFilter, setShowFilter] = useState(true)
    const [showRequiredMessage, setShowRequiredMessage] = useState(false)
    const [serialNumberTextError, setSerialNumberTextError] = useState(false)
    const [serialNumberLengthError, setSerialNumberLengthError] = useState(false)
    const [filterApplied, setFilterApplied] = useState(false)
    const [serialNumber, setSerialNumber] = useState("")

    const generalRequiredMessage = language === 'EN'? 'Please provide above information!':'দয়া করে উপরের তথ্যটি দিন!'

    const classes = useStylesIconButton();

    const FirmwareUpdateListColumns = [
        {
            field: 'dongle_serial_number',
            title: language === 'EN'? 'Dongle Serial Numbere':'যন্ত্র',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'old_firmware_version',
            title: language === 'EN'? 'Old Firmware Version':'পূর্বের ফার্মওয়্যার',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
            // disableClick: true,
        },
        {
            field: 'new_firmware_version',
            title: language === 'EN'? 'New Firmware Version':'বর্তমান ফার্মওয়্যার',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
        {
            field: 'is_successful',
            title: language === 'EN'? 'Status':'অবস্থা',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: rowData => (rowData.is_successful===true? <Badge variant={'success'}>Successful</Badge> : <Badge variant={'danger'}>Failed</Badge>)
        },
        {
            field: 'created_at',
            title: language === 'EN'? 'Created At':'আপডেট শুরু হওয়ার সময়',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: rowData => (rowData.created_at? new DateTimeFormat(rowData.created_at).localDisplayTime : "--")
        },
        {
            field: 'created_by_name',
            title: language === 'EN'? 'Created by':'আপডেট শুরু হয়েছে যার দ্বারা',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
    ]

    const {
        GET_FIRMWARE_UPDATE_LIST,
        RETURN_TO_INITIAL,
        otaList,
    } = props


    useEffect(()=>{
        setLanguage(props.language)
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)
        GET_FIRMWARE_UPDATE_LIST()
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    const filterTheList = () => {
        if (!serialNumber) {
            setShowRequiredMessage(true)
        } else if (isNaN(serialNumber) || serialNumber.includes('.')) {
            setSerialNumberTextError(true)
        } else if (serialNumber.length !== 8) {
            setSerialNumberLengthError(true)
        } else {
            setFilterApplied(true)
            GET_FIRMWARE_UPDATE_LIST({
                dongle_serial_number: serialNumber
            })
        }
    }

    const clearAllErrorMessages = () => {
        setShowRequiredMessage(false)
        setSerialNumberTextError(false)
        setSerialNumberLengthError(false)
    }

    const resetFilter = () => {
        clearAllErrorMessages()
        setSerialNumber("")
        if (filterApplied) {
            setFilterApplied(false)
            GET_FIRMWARE_UPDATE_LIST()
        }
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={"Firmware List"} showBackButton={false} showCardHeader={false}>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{language === 'EN'? 'OTA Updates':'ফার্মওয়্যার আপডেটের তালিকা'}</b></h1>
                        </Toolbar.Title>
                        <Toolbar.ToolbarContainer>
                            <Toolbar.ToolbarContainer.Items>
                                <IconButton onClick={filterToggle} classes={classes}>
                                    <FilterListIcon color="disabled" fontSize="large"/>
                                </IconButton>
                            </Toolbar.ToolbarContainer.Items>
                        </Toolbar.ToolbarContainer>
                    </Toolbar>
                </div>
            </div>
            <hr/>
            {showFilter?
                <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Form>
                                <div className={'row g-3'}>
                                    <div className={"col-md-6"}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN'? "Dongle serial number":"যন্ত্রের ক্রমিক নাম্বার"}<span className="required text-danger">*</span></Form.Label>
                                            </div>
                                            <SolInput
                                                name={'searchText'}
                                                type="text"
                                                placeholder={language === "EN"? "Provide dongle serial number. Ex: 44001001":"যন্ত্রের ক্রমিক নাম্বার লিখুন। উদাঃ 44001001"}
                                                autoComplete={"off"}
                                                value={serialNumber}
                                                onChange = {(event) => {
                                                    clearAllErrorMessages()
                                                    setSerialNumber(event.target.value)
                                                }}
                                            />
                                            {showRequiredMessage? <><div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}
                                            {serialNumberTextError? <><div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please provide only numbers!':'দয়া করে শুধু সংখ্যা লিখুন!'}</div></>:null}
                                            {serialNumberLengthError? <><div className="text-danger">
                                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Serial number must be of 8 digits!':'ক্রমিক নাম্বার অবশ্যই ৮ সংখ্যার হতে হবে!'}</div></>:null}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={"col-md-12"}>
                                        <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                            {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় যান'}
                                        </Button>
                                        <Button variant="warning" size={'sm'} type="button"
                                                disabled={(showRequiredMessage || serialNumberIsInvalid)? true:false}
                                                onClick={()=> {filterTheList()}} className={'ml-3'}>
                                            {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                            {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <hr/></>:null}
            <div>
                <DataTableContainer>
                    {props.listLoading !== true ? <DataTable
                        language={language}
                        noDataAvailableMessageInBangla={'কোন ওটিএ আপডেট নেই'}
                        columns={FirmwareUpdateListColumns}
                        data={otaList?otaList.map((item,index)=>({...item, sl: index+1})):[]}
                        itemsPerPage={10}
                    /> : <LoadingSpinner loadingSubText={language == 'EN'? 'Generating OTA update list ..': 'ওটিএ আপডেটের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                </DataTableContainer>
            </div>
        </ContentWrapper>
    );
};


export default connect((state) => {
    return {
        language: state.auth.language,
        isLoading: state.otaReducer.isLoading,
        otaList: state.otaReducer.otaList,
        listLoading: state.otaReducer.listLoading,
    }
}, {...actions}) (FirmwareUpdateHistory);
