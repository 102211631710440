const featureNames = {
    tokenFeature : 'token',
    userFeature: 'user',
    roleFeature: 'role',
    driverFeature: 'driver',
    checkOutFeature: 'driver_checkout',
    checkInFeature: 'driver_checkin',
    deviceFeature: 'device',
    smartDongleFeature: 'dongle',
    batteryFeature: 'battery',
    vendorFeature: 'vendor',
    modelFeature: 'model',
    batteryRentFeature: 'battery_rent',
    evFeature: 'ev',
    garageFeature: 'garage',
    manualTransactionFeature: 'manual_payment',
    historicalDataFeature: 'historical_data',
    appEventFeature: 'app_event',
    otaUpdateFeature: 'ota_update',
    firmwareFeature: 'firmware',
    hardwareFeature: 'hardware',
    organizationFeature: 'organization',
    mobileWalletFeature: 'mobile_wallet',
    depositFeature: 'topup',
    settlementFeature: 'payment',
    productionLogFeature: 'production_log',
    eventHistoryFeature: 'event_history',
    contractManagementFeature: 'contract',
    invoiceFeature: 'invoice',
    transactionFeature: 'activity',
    manualCashPaymentFeature: 'topup', // Note that, 'depositFeature' and 'manualCashPayment' has the same value - Noor Reza, 29th Jan, 2024 5:21 PM
    invoiceSettlementFeature: 'payment_settlement',
    financierFeature: 'financier',
    dashboardOverviewFeatureForGarage: 'dashboard_overview',
    dashboardOverviewFeatureForTransaction: 'dashboard_overview_transaction'
};

export const getFeatureName = (feature) => {
    return featureNames.hasOwnProperty(feature)? featureNames[feature]: undefined;
};
