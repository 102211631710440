import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import {Button, Form, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {DataTable, LoadingSpinner, SolInput} from "../../../../components";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import moment from "moment";
import {useMediaQuery} from "react-responsive";
import Alert from '@mui/material/Alert';
import {ExportToCsv} from "export-to-csv";
import {csvToArray} from "../../../../utils/utilityFunctions";
import {showNotifications} from "../../../../utils/notification";
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import AlertTitle from '@mui/material/AlertTitle';
import Select from "react-select";

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const NewCashDeposit = ({ ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [language, setLanguage] = useState("EN");
    const [borrowers, setBorrowers] = useState([]);
    const classes = useStylesIconButton();

    const invalidName = language === 'EN'? 'Invalid name!':'ভুল নাম!'
    const informationIsRequired = 'Above information is required!'
    const wrongFileTypeMessage = 'Invalid file type!'
    const wrongFileSizeMessage = 'File size has exceeded the max size limit!'

    const {
        // RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        // TODO: Collect Borrower's name
        // TODO: We should use different action for this to keep it separate from the filter form
        return ()=>{
            // RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT()
        }
    }, [])

    const savePayment = (data) => {
        console.log("here")
        console.log(data)
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(savePayment)}>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Deposit Amount (Tk)&nbsp;<span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                type={"number"}
                                name={"amount"}
                                step={"any"}
                                placeholder={"0.0"}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired
                                })}
                            />
                            {errors.amount && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.amount.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Borrower's Name&nbsp;<span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"borrower"}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Borrower"s name'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingBorrowers}
                                        isLoading={props.collectingBorrowers}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={borrowers}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No borrowers available"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Smart Battery Number&nbsp;<span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"smart_battery"}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Smart Battery Number'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingBorrowers}
                                        isLoading={props.collectingBorrowers}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={borrowers}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No smart battery available"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Document&nbsp;<span className="required text-danger">*</span></Form.Label><br/>
                            </div>
                            <SolInput type="file" name={"receipt_file"} ref={register({
                                required: informationIsRequired,
                                validate: {
                                    isValidType: value => ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                        "application/vnd.ms-excel",
                                        "application/pdf", "text/csv",
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                        "application/msword"].includes(value[0].type) || wrongFileTypeMessage,
                                    isValidFileSize: value => (value[0].size <= 100000 || !value) || wrongFileSizeMessage
                                }
                            })}/>
                            {errors.receipt_file && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.receipt_file.message}</div>}
                            <small>Accepted file types: *.xlsx, *.xls, *.pdf, *.csv, *.docx, *.doc</small><br/>
                            <small>Max accepted size: 100 KB</small>
                        </Form.Group>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="warning" size={'lg'} type="submit"
                                disabled={props.invoiceCollectionInProgress} className={'ml-3 float-right'}>
                            Recharge
                        </Button>
                        <Button variant="outline-dark" size={'lg'} onClick={() => console.log("here")}
                                className={'float-right'}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

NewCashDeposit.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        invoices: state.borrowersReducer.invoices
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(NewCashDeposit));