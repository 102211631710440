import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_DEVICE_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/devices`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_DEVICE_LIST_REQUEST,
                actionTypes.GET_DEVICE_LIST_SUCCESS,
                actionTypes.GET_DEVICE_LIST_FAILURE
            ]
        }
    }),

    GET_FIRMWARE_LIST: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/firmwares', payload),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_FIRMWARE_LIST_REQUEST,
                actionTypes.GET_FIRMWARE_LIST_SUCCESS,
                actionTypes.GET_FIRMWARE_LIST_FAILURE
            ]
        }
    }),

    GET_HARDWARE_LIST: (filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler(`/hardwares`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_HARDWARE_LIST_REQUEST,
                actionTypes.GET_HARDWARE_LIST_SUCCESS,
                actionTypes.GET_HARDWARE_LIST_FAILURE
            ]
        }
    }),

    GET_VENDOR_LIST: (filter) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/vendor', filter),
            method: 'GET',
            backend_slug: 'battery_management',
            types: [
                actionTypes.GET_VENDOR_LIST_REQUEST,
                actionTypes.GET_VENDOR_LIST_SUCCESS,
                actionTypes.GET_VENDOR_LIST_FAILURE
            ]
        }
    }),

    GET_MODELS_LIST: (filter) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/model', filter),
            method: 'GET',
            backend_slug: 'battery_management',
            types: [
                actionTypes.GET_MODELS_LIST_REQUEST,
                actionTypes.GET_MODELS_LIST_SUCCESS,
                actionTypes.GET_MODELS_LIST_FAILURE
            ]
        }
    }),

    GET_GARAGE_LIST: (filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/garages', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_GARAGE_LIST_REQUEST,
                actionTypes.GET_GARAGE_LIST_SUCCESS,
                actionTypes.GET_GARAGE_LIST_FAILURE
            ]
        }
    }),

    GET_BRANCHES_LIST: (filter = {}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/branches', filter),
            method: 'GET',
            // backend_slug: 'garage_management',
            types: [
                actionTypes.GET_BRANCH_LIST_REQUEST,
                actionTypes.GET_BRANCH_LIST_SUCCESS,
                actionTypes.GET_BRANCH_LIST_FAILURE
            ]
        }
    }),

    CREATE_NEW_BRANCH: (payload)=>({
        [CALL_API]: {
            endpoint: '/branches',
            method: 'POST',
            body: payload,
            types: [
                actionTypes.CREATE_NEW_BRANCH_REQUEST,
                actionTypes.CREATE_NEW_BRANCH_SUCCESS,
                actionTypes.CREATE_NEW_BRANCH_FAILURE
            ]
        }
    }),

    GET_GARAGE_DETAILS: (filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/garage/detail', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_GARAGAES_DETAILS_REQUEST,
                actionTypes.GET_GARAGAES_DETAILS_SUCCESS,
                actionTypes.GET_GARAGAES_DETAILS_FAILURE
            ]
        }
    }),

    GET_ORGANISATIONS: ()=>({
        [CALL_API]: {
            endpoint: `/organizations`,
            method: 'GET',
            types: [
                actionTypes.GET_ORGANISATIONS_REQUEST,
                actionTypes.GET_ORGANISATIONS_SUCCESS,
                actionTypes.GET_ORGANISATIONS_FAILURE,
            ]
        }
    }),

    COLLECT_EVENTS_HISTORY: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/event_histories`, filter),
            backend_slug: 'device_management',
            method: 'GET',
            types: [
                actionTypes.COLLECT_EVENTS_HISTORY_REQUEST,
                actionTypes.COLLECT_EVENTS_HISTORY_SUCCESS,
                actionTypes.COLLECT_EVENTS_HISTORY_FAILURE
            ]
        }
    }),

    SAVE_EVENT: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/event_histories',
            method: 'POST',
            backend_slug: 'device_management',
            body : payload,
            types: [
                actionTypes.SAVE_EVENT_REQUEST,
                actionTypes.SAVE_EVENT_SUCCESS,
                actionTypes.SAVE_EVENT_FAILURE
            ]
        }
    }),

    COLLECT_BORROWERS: ()=>({
        [CALL_API]: {
            endpoint: `/users?user_type=garage_owner`,
            method: 'GET',
            types: [
                actionTypes.COLLECT_BORROWERS_REQUEST,
                actionTypes.COLLECT_BORROWERS_SUCCESS,
                actionTypes.COLLECT_BORROWERS_FAILURE,
            ]
        }
    }),

    COLLECT_GARAGE_DETAILS: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/garage/detail', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_DETAILS_REQUEST,
                actionTypes.COLLECT_GARAGE_DETAILS_SUCCESS,
                actionTypes.COLLECT_GARAGE_DETAILS_FAILURE
            ]
        }
    }),

    COLLECT_FINANCIERS_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/organizations',
            method: 'GET',
            types: [
                actionTypes.COLLECT_FINANCIERS_LIST_REQUEST,
                actionTypes.COLLECT_FINANCIERS_LIST_SUCCESS,
                actionTypes.COLLECT_FINANCIERS_LIST_FAILURE,
            ]
        }
    }),

    COLLECT_DIVISIONS: ()=>({
        [CALL_API]: {
            endpoint: '/geo/divisions',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DIVISIONS_REQUEST,
                actionTypes.COLLECT_DIVISIONS_SUCCESS,
                actionTypes.COLLECT_DIVISIONS_FAILURE,
            ]
        }
    }),

    COLLECT_DISTRICTS: (divisionId)=>({
        [CALL_API]: {
            endpoint: '/geo/districts/' + divisionId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_DISTRICTS_REQUEST,
                actionTypes.COLLECT_DISTRICTS_SUCCESS,
                actionTypes.COLLECT_DISTRICTS_FAILURE,
            ]
        }
    }),

    COLLECT_SUB_DISTRICTS: (districtId)=>({
        [CALL_API]: {
            endpoint: '/geo/upazilas/' + districtId,
            method: 'GET',
            types: [
                actionTypes.COLLECT_SUB_DISTRICTS_REQUEST,
                actionTypes.COLLECT_SUB_DISTRICTS_SUCCESS,
                actionTypes.COLLECT_SUB_DISTRICTS_FAILURE,
            ]
        }
    }),

    COLLECT_ZONE_AREA_FOR_BRANCH: (orgGuid)=>({
        [CALL_API]: {
            endpoint: '/organization/zone/' + orgGuid,
            method: 'GET',
            types: [
                actionTypes.COLLECT_ZONE_AREA_FOR_BRANCH_REQUEST,
                actionTypes.COLLECT_ZONE_AREA_FOR_BRANCH_SUCCESS,
                actionTypes.COLLECT_ZONE_AREA_FOR_BRANCH_FAILURE
            ]
        }
    }),

    CLEAN_UP_COMMON_STATES_RELATED_TO_GARAGE_DETAILS_ONLY: () => ({ type: actionTypes.CLEAN_UP_COMMON_STATES_RELATED_TO_GARAGE_DETAILS_ONLY }),

    CLEAN_UP_STATES_RELATED_TO_EVENTS_HISTORY_MODAL: () => ({ type: actionTypes.CLEAN_UP_STATES_RELATED_TO_EVENTS_HISTORY_MODAL }),

    RETURN_TO_INITIAL_STATES_OF_BRANCH_FORM: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATES_OF_BRANCH_FORM }),

    RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER })

};
