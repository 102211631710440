import React, { useEffect, useState } from 'react';
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import {FilterButton, GenericModal, Toolbar} from "../../../components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DataTable, DataTableContainer, FilterInput, FilterReactSelect } from "../../../components";
import { transactionActions } from "../actions";
import { actions as commonActions } from '../../commonReduxActions/actions';
import { LoadingSpinner } from '../../../components'
import { connect } from "react-redux";
import { Button, Form, OverlayTrigger, Popover, Spinner, FormCheck } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { theme, useStylesForDatePicker } from "../../../utils/muiPickerCustomStyle";
import moment from "moment";
import 'moment/locale/bn';
import 'moment/locale/en-gb';
import { DayPickerRangeController, DayPickerSingleDateController } from "react-dates";
import { SolTypography } from '../../../components';
import { useSubheader } from "../../../../_metronic/layout";
import {exportInExcel} from "../../../utils/excelExport";
import {toBengaliNumber} from "bengali-number";
import Stack from '@mui/material/Stack';
import Avatar from "@mui/material/Avatar";
import { useMediaQuery } from "react-responsive";
import Overlay from "react-bootstrap/Overlay";
import {SolInput} from "../../../components/SolStyledComponents/components";
import InfoIcon from "@mui/icons-material/Info";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Select from "react-select";
import {ValueContainer} from '../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect'
import {Option} from '../../../utils/reactSelectRelated/showOptionsWithCheckbox'
import {customStylesForMultiSelectField} from '../../../utils/reactSelectRelated/customStylesForMultiSelect'
import {groupSelectedOptions} from '../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect'
import {formulateTextFromSelectedItems} from '../../../utils/multiSelectValueFormation/formatForExportedFile'
import {formulateApiRequestDataFromSelectedItems} from '../../../utils/multiSelectValueFormation/formatForApiRequest'
import {customStylesForSingleSelectField} from "../../../utils/reactSelectRelated/customStylesForSingleSelect";
import {getServiceName} from "../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../auth/authorization";
import BulkInvoiceExtension from "./bulkInvoiceExtension";
import {requestCycle} from "../../borrowers/utils";
import {toast} from "react-toastify";
import {showNotifications} from "../../../utils/notification";

const Invoices = props => {
    const { clearErrors, setError, setValue, reset, handleSubmit, control, errors, register } = useForm()
    const subheader = useSubheader();
    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState("EN");
    const [showFilter, setShowFilter] = useState(true);
    const [filterApplied, setFilterApplied] = useState(false);
    const obj_filters = ['lease_type', 'invoice_status']

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [borrower, setBorrower] = useState('');
    const [garage, setGarage] = useState('');
    const [financier, setFinancier] = useState('');
    const [branch, setBranch] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [contractType, setContractType] = useState('');
    const [invoices, setInvoices] = useState(undefined);
    const [garages, setGarages] = useState(undefined);
    const [borrowers, setBorrowers] = useState([]);
    const [financiers, setFinanciers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [filter, setFilter] = useState(null);

    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [page, setPage] = useState(1);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    // variables for Invoice Date
    const [targetForInvoiceDateRangePicker, setTargetForInvoiceDateRangePicker] = useState(null);
    const [showInvoiceDateRangePicker, setShowInvoiceDateRangePicker] = useState(false);
    const [invoiceDateRangeStart, setInvoiceDateRangeStart] = useState(null);
    const [invoiceDateRangeEnd, setInvoiceDateRangeEnd] = useState(null);
    const [invoiceDateRange, setInvoiceDateRange] = useState('');
    const [focusedInputForInvoiceDate, setFocusedInputForInvoiceDate] = useState('startDate');

    // variables for Due Date
    const [targetForDueDateRangePicker, setTargetForDueDateRangePicker] = useState(null);
    const [showDueDateRangePicker, setShowDueDateRangePicker] = useState(false);
    const [dueDateRangeStart, setDueDateRangeStart] = useState(null);
    const [dueDateRangeEnd, setDueDateRangeEnd] = useState(null);
    const [dueDateRange, setDueDateRange] = useState('');
    const [focusedInputForDueDate, setFocusedInputForDueDate] = useState('startDate');

    // Bulk invoice extension related
    const [showInvoiceExtensionModal, setShowInvoiceExtensionModal] = useState(false);
    const garageServiceName = getServiceName('garageService')
    const contractManagementFeatureName = getFeatureName('contractManagementFeature')
    const extendContractActionName = getActionName('contractExtendAction')

    const invoicesColumn = [
        {
            field: 'created_at',
            title: "Invoice Date",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: "nowrap"}}>{moment(rowData.created_at).format("MMM DD, YYYY")}</span>
                </>
            }
        },
        {
            field: 'invoice_for',
            title: "Particulars",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <span ><SolTypography.Title > SB # {`${rowData.device_sl}`} </SolTypography.Title></span>
                    <span style={{whiteSpace: "nowrap"}}>Invoice # {`${rowData.invoice_id}`}</span><br/>
                    <span style={{whiteSpace: "nowrap"}}>Contract # {rowData.contract_id}</span>
                    {rowData.installment_no? <>
                        <br/><span style={{whiteSpace: "nowrap"}}>Installment # {rowData.installment_no}</span>
                    </>:''}
                </>
            }
        },
        {
            field: 'borrower_details',
            title: "Borrower's Name",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: "nowrap"}}>{rowData.borrowerName}</span><br/>
                    {rowData.garage_details? <>
                        <span style={{whiteSpace: "nowrap"}}>{rowData.garageName}</span><br/>
                        <span>{rowData.garageUpazila && rowData.garageDistrict? <>
                            {rowData.garageUpazila}, {rowData.garageDistrict}
                        </>:<>{rowData.garageDistrict? <>
                            {rowData.garageDistrict}
                        </>:<>
                            {rowData.garageUpazila? <>
                                {rowData.garageUpazila}
                            </>:null}
                        </>}</>}</span>
                    </>:null}
                </>
            }
        },
        {
            field: 'payable_amount',
            title: "Invoice Amount (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return <>
                    <h6 style={{whiteSpace: 'nowrap'}}><strong>{new Intl.NumberFormat('en-IN').format(rowData.payable_amount.toFixed(2))}</strong></h6>
                    <span ><SolTypography.SubTitle secondary>{rowData.invoice_for === 'installment'? "Installment Fee":rowData.invoice_for === 'downpayment'? "Downpayment Fee":"Rent Fee"}</SolTypography.SubTitle></span>
                </>
            }
        },
        {
            field: 'financier_name',
            title: "Payable To",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            emptyValue: () => {
                return "-"
            },
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>{rowData.financier_name}</span>
                </>
            }
        },
        {
            field: 'created_at',
            title: "Due Date",
            emptyValue: () => {
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
            render: (rowData) => {
                return <>
                    <span style={{whiteSpace: 'nowrap'}}>{moment(rowData.contract_details.contract_type === 'rent' || rowData.contract_details.contract_type === 'downpayment'? rowData.created_at:rowData.installment_due_on).format("MMM DD, YYYY")}</span>
                </>
            }
        },
        // {
        //     field: 'paid_amount',
        //     title: "Paid (Tk)",
        //     cellStyle: {
        //         textAlign: 'right'
        //     },
        //     headerStyle: {
        //         paddingRight: '0',
        //         textAlign: 'right'
        //     },
        //     render: (rowData)=> {
        //         return <>
        //             <h6 style={{whiteSpace: 'nowrap'}}><strong>{new Intl.NumberFormat('en-IN').format(rowData.paid_amount.toFixed(2))}</strong></h6>
        //         </>
        //     }
        // },
        // {
        //     field: 'payable_amount',
        //     title: 'Due (Tk)',
        //     headerStyle: {
        //         paddingRight: '0',
        //         textAlign: 'right'
        //     },
        //     cellStyle: {
        //         textAlign: 'right'
        //     },
        //     emptyValue: () => {
        //         return "-"
        //     },
        //     render: (rowData)=> {
        //         let due = rowData.payable_amount - rowData.paid_amount
        //         return <>
        //             <h6 style={{whiteSpace: 'nowrap'}}><strong>{new Intl.NumberFormat('en-IN').format(due.toFixed(2))}</strong></h6>
        //         </>
        //     }
        // },
        {
            field: 'invoice_status',
            title: 'Payment Status',
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            cellStyle: {
                textAlign: 'right'
            },
            render: (rowData)=> {
                const DATA_MAP = {
                    "due": { "cls": 'outline-danger', "text": "Due" },
                    "overdue": { "cls": 'danger', "text": "Overdue" },
                    "partial_paid": { "cls": "outline-warning", "text": "Partial" },
                    "paid": { "cls": "outline-success", "text": "Paid" },
                    "cancelled": { "cls": "outline-info", "text": "Cancelled" },
                }

                // console.log(rowData?.invoice_status)

                return <>

                    <Button variant={DATA_MAP[rowData?.invoice_status].cls} size={'sm'}>{DATA_MAP[rowData?.invoice_status].text}</Button>
                </>
            }
        }
    ]

    const {
        COLLECT_INVOICES_LIST,
        COLLECT_INVOICES_LIST_FOR_EXPORT,
        GET_GARAGE_LIST,
        GET_BRANCHES_LIST,
        COLLECT_BORROWERS,
        COLLECT_FINANCIERS_LIST,
        EXTEND_OVERDUE_INVOICES,
        EXTEND_UPCOMING_INSTALLMENTS,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)

        if (checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [extendContractActionName])) {
            subheader.setActionButtons(
                <>
                    <div className="d-flex align-item-center justify-content-end">
                        <Button variant="warning" size={'sm'} type="button" style={{
                            backgroundColor: '#F18D00 !important',
                        }} onClick={initiateBulkExtensionProcess} className={'ml-1'}>
                            <EditCalendarIcon fontSize={"small"}/> Bulk Invoices Extension
                        </Button>
                    </div>
                </>
            )
        } else {
            subheader.setActionButtons(null)
        }

        subheader.setBreadCrumbComponent(null)
        COLLECT_BORROWERS()
        GET_GARAGE_LIST()
        if (props.isSolshareUser) {
            COLLECT_FINANCIERS_LIST()
        }
        // GET_BRANCHES_LIST()
        COLLECT_INVOICES_LIST({page: 1})
        return () => {
            RETURN_TO_INITIAL_STATE()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, [])

    const invoicesWithoutPagination = () => {
        let payload = { ...filter}
        COLLECT_INVOICES_LIST_FOR_EXPORT({...payload})
    }

    useEffect(() => {
        setInvoiceDateRange(invoiceDateRangeStart && invoiceDateRangeEnd ? `${moment(invoiceDateRangeStart).format("DD MMM, YYYY")} - ${moment(invoiceDateRangeEnd).format("DD MMM, YYYY")}` : '');
    }, [invoiceDateRangeStart, invoiceDateRangeEnd])

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [clickCountForInvoiceDateRangePicker, setClickCountForInvoiceDateRangePicker] = useState(0)
    const onDatesChangeAtInvoiceDate = ({startDate, endDate}) => {
        setClickCountForInvoiceDateRangePicker(clickCountForInvoiceDateRangePicker + 1)
        if (startDate && endDate) {
            setInvoiceDateRangeStart(startDate)
            setInvoiceDateRangeEnd(endDate)
            if (clickCountForInvoiceDateRangePicker === 1) {
                setClickCountForInvoiceDateRangePicker(0)
                setShowInvoiceDateRangePicker(false)
            }
        } else if (startDate && !endDate) {
            setInvoiceDateRangeStart(startDate)
            setInvoiceDateRangeStart(startDate)
        }
    }

    const handleFocusChangeForInvoiceDate = (input) => {
        if (!input) {
            setFocusedInputForInvoiceDate('startDate')
        } else {
            setFocusedInputForInvoiceDate(input)
        }
    }

    const showHelpfulText = () => {
        return <>
            <div className={'container'} style={{color: "#F18D00"}}><InfoIcon size={'sm'}/> Selection is completed
                after <strong>two clicks on a single date</strong> or <strong>single click on two dates.</strong></div>
        </>
    }
    const invoiceDatePopover = () => {
        return <>
            <Overlay
                show={showInvoiceDateRangePicker}
                target={targetForInvoiceDateRangePicker}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={invoiceDateRangeStart}
                            endDate={invoiceDateRangeEnd}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            isOutsideRange={disableFutureDt}
                            onDatesChange={onDatesChangeAtInvoiceDate}
                            focusedInput={focusedInputForInvoiceDate}
                            onFocusChange={(focusedInput) => handleFocusChangeForInvoiceDate(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "invoice_date") {
                                    setShowInvoiceDateRangePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    useEffect(() => {
        setDueDateRange(dueDateRangeStart && dueDateRangeEnd ? `${moment(dueDateRangeStart).format("DD MMM, YYYY")} - ${moment(dueDateRangeEnd).format("DD MMM, YYYY")}` : '');
    }, [dueDateRangeStart, dueDateRangeEnd])

    const [clickCountForDueDateRangePicker, setClickCountForDueDateRangePicker] = useState(0)
    const onDatesChangeAtDueDate = ({startDate, endDate}) => {
        setClickCountForDueDateRangePicker(clickCountForDueDateRangePicker + 1)
        if (startDate && endDate) {
            setDueDateRangeStart(startDate)
            setDueDateRangeEnd(endDate)
            if (clickCountForDueDateRangePicker === 1) {
                setClickCountForDueDateRangePicker(0)
                setShowDueDateRangePicker(false)
            }
        } else if (startDate && !endDate) {
            setDueDateRangeStart(startDate)
            setDueDateRangeStart(startDate)
        }
    }

    const handleFocusChangeForDueDate = (input) => {
        if (!input) {
            setFocusedInputForDueDate('startDate')
        } else {
            setFocusedInputForDueDate(input)
        }
    }

    const invoiceDueDatePopover = () => {
        return <>
            <Overlay
                show={showDueDateRangePicker}
                target={targetForDueDateRangePicker}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={dueDateRangeStart}
                            endDate={dueDateRangeEnd}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            onDatesChange={onDatesChangeAtDueDate}
                            focusedInput={focusedInputForDueDate}
                            onFocusChange={(focusedInput) => handleFocusChangeForDueDate(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "installment_due_on") {
                                    setShowDueDateRangePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    // Saving latest garage information
    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            let selectedGarages = []
            let garageList = garages.map((item) => {
                if (garage) {
                    for (let i=0; i<garage.length; i++) {
                        if (garage[i].value === item.pk) {
                            selectedGarages.push({
                                ...item,
                                value: item.pk,
                                label: item.name
                            })
                        }
                    }
                }
                return {
                    ...item,
                    value: item.pk,
                    label: item.name
                }
            })
            if (selectedGarages.length > 0) {
                setGarage(selectedGarages)
                setGarages(groupSelectedOptions(selectedGarages, garageList))
            } else {
                setGarages(garageList)
            }
        }
    }, [props.garages])

    useEffect(() => {
        if (props.invoices && props.invoices.results && props.invoices.results.length > -1) {
            const invoices = props.invoices.results;
            setInvoices(invoices.map((invoice) => {
                let borrowerName = invoice.borrower_details?.name? invoice.borrower_details.name:''
                let garageName = invoice.garage_details?.name? invoice.garage_details.name:''
                let garageDistrict = invoice.garage_details?.district? invoice.garage_details.district:''
                let garageUpazila = invoice.garage_details?.upazila? invoice.garage_details.upazila:''
                if (garages && garages.length > 0) {
                    let filteredData = garages.filter(garage => garage.pk === invoice.garage_guid)
                    if (filteredData.length > 0) {
                        if (invoice.borrower_details) {
                            borrowerName = filteredData[0].garage_owner_name
                        }
                        if (invoice.garage_details) {
                            garageName = filteredData[0].name
                            garageDistrict = filteredData[0].district
                            garageUpazila = filteredData[0].upazila
                        }
                    }
                }
                return {
                    ...invoice,
                    borrowerName: borrowerName,
                    garageName: garageName,
                    garageDistrict: garageDistrict,
                    garageUpazila: garageUpazila
                }
            }))
        } else if (props.invoices && !props.invoices.results) {
            setInvoices([])
        } else {
            setInvoices(undefined)
        }
    }, [props.invoices, garages]);

    useEffect(() => {
        if (props.invoicesForExport && props.invoicesForExport.length > -1) {
            const invoices = props.invoicesForExport
            let presentTime = moment()
            let invoicesLength = invoices.length
            let totalInvoicesText = language === 'EN'? "Total Invoices: " + invoicesLength:"চালানের সংখ্যা: " + toBengaliNumber(invoicesLength)
            let exportText = language === 'EN'? "Exported At: " + presentTime.format("Do MMMM,YYYY hh:mm A"):"এক্সেল ফাইল ডাউনলোডের সময়: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let excelRows = [
                ["Invoices", "", "", "", "", "", "", "", "", "", ""],
                [totalInvoicesText, "", "", "", "", "", "", "", "", "", ""],
                [exportText, "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", ""],
                ["Invoice Date", "Ref", "Particular", "Borrower", "Garage", "Payable To", "Due Date", "Payment Status",
                    "Amount (Tk)", "Paid (Tk)", "Due (Tk)"]
            ]
            if (filterApplied) {
                if (filter.device_sl) {
                    let text = "Smart Battery: " + filter.device_sl
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.created_at_from && filter.created_at_to) {
                    let text = "Invoice Date: " + moment(filter.created_at_from).format("DD MMM,YYYY") +
                        " - " + moment(filter.created_at_to).format("DD MMM,YYYY")
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.invoice_due_from && filter.invoice_due_to) {
                    let text = "Due Date: " + moment(filter.invoice_due_from).format("DD MMM,YYYY") +
                        " - " + moment(filter.invoice_due_to).format("DD MMM,YYYY")
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.borrower_guid && borrower) {
                    let text = formulateTextFromSelectedItems('Borrower', borrower)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.garage_guid && garage) {
                    let text = formulateTextFromSelectedItems('Garage', garage)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.financier_guid && financier) {
                    let text = formulateTextFromSelectedItems('Financier', financier)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.branch_guid && branch) {
                    let text = formulateTextFromSelectedItems('Branch', branch)
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.lease_type) {
                    let text = "Contract Type: " + contractType.label
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
                if (filter.invoice_status) {
                    let text = "Payment Status: " + paymentStatus.label
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", ""])
                }
            }
            for (let i=0; i<invoices.length; i++) {
                let status = ''
                if (invoices[i].invoice_status === 'due') {
                    status = 'Due'
                } else if (invoices[i].invoice_status === 'partial_paid') {
                    status = 'Partially Paid'
                } else if (invoices[i].invoice_status === 'overdue') {
                    status = 'Overdue'
                } else {
                    status = 'Paid'
                }
                let due = invoices[i].payable_amount - invoices[i].paid_amount
                let borrowerName = invoices[i].borrower_details?.name? invoices[i].borrower_details.name:''
                let garageName = invoices[i].garage_details?.name? invoices[i].garage_details.name:''
                if (garages && garages.length > 0) {
                    let filteredData = garages.filter(garage => garage.pk === invoices[i].garage_guid)
                    if (filteredData.length > 0) {
                        if (invoices[i].borrower_details && filteredData) {
                            borrowerName = filteredData[0].garage_owner_name
                        }
                        if (invoices[i].garage_details && filteredData) {
                            garageName = filteredData[0].name
                        }
                    }
                }
                excelRows.push([
                    moment(invoices[i].created_at).format("MMM DD, YYYY"),
                    invoices[i].contract_id,
                    invoices[i].invoice_for.split("_").map((partOfName) => {
                        return partOfName.charAt(0).toUpperCase() + partOfName.slice(1)
                    }).join(' '),
                    borrowerName,
                    garageName,
                    invoices[i].organization_name,
                    moment(invoices[i].contract_details.contract_type === 'rent'? invoices[i].created_at:invoices[i].installment_due_on).format("MMM DD, YYYY"),
                    status,
                    new Intl.NumberFormat('en-IN').format(invoices[i].payable_amount.toFixed(2)),
                    new Intl.NumberFormat('en-IN').format(invoices[i].paid_amount.toFixed(2)),
                    new Intl.NumberFormat('en-IN').format(due.toFixed(2))
                ])
            }
            let noDataAvailableText =  language === 'EN'? "No data available":"কোন তথ্য নেই"
            invoicesLength > 0? excelRows.push(["", "", "", "", "", "", "", "", "", "", "", ""]):
                excelRows.push(["", "", "", "", "", noDataAvailableText, "", "", "", "", "", ""])
            let fileName = 'invoices_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.invoicesForExport])

    useEffect(() => {
        if (props.borrowers && props.borrowers.length > -1) {
            const borrowers = props.borrowers;
            let selectedBorrowers = []
            let borrowerList = borrowers.map((item) => {
                let label = item.name
                let garageName = item.garage_name
                if (garages && garages.length > 0) {
                    let filteredData = garages.filter(garage => garage.pk === item.garage_guids[0])
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                    }
                }
                label += ' (' + garageName + ')'

                if (borrower) {
                    for (let i=0; i<borrower.length; i++) {
                        if (borrower[i].value === item.pk) {
                            selectedBorrowers.push({
                                ...item,
                                label: label,
                                value: item.pk
                            })
                        }
                    }
                }

                return {
                    ...item,
                    label: label,
                    value: item.pk
                }
            })
            if (selectedBorrowers.length > 0) {
                setBorrower(selectedBorrowers)
                setBorrowers(groupSelectedOptions(selectedBorrowers, borrowerList))
            } else {
                setBorrowers(borrowerList)
            }
        } else {
            setBorrowers([])
        }
    }, [props.borrowers, garages])

    useEffect(() => {
        if (props.financiers && props.financiers.length > -1) {
            setFinanciers(
                props.financiers.map((item) => {
                    return {
                        ...item,
                        value: item.pk,
                        label: item.name
                    }
                })
            )
        } else {
            setFinanciers([])
        }
    }, [props.financiers])

    useEffect(() => {
        if (props.branches && props.branches.length > -1) {
            setBranches(props.branches.map((branch) => {
                return {
                    ...branch,
                    value: branch.pk,
                    label: branch.name
                }
            }))
        } else {
            setBranches([])
        }
    }, [props.branches])

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    }, [start, end])

    const resetFilterValues = () => {
        setInvoiceDateRangeStart(null)
        setInvoiceDateRangeEnd(null)
        setDueDateRangeStart(null)
        setDueDateRangeEnd(null)
        setBorrower('')
        setGarage('')

        if (props.isSolshareUser && financier) {
            setFinancier('')
            // GET_BRANCHES_LIST()
        }

        setBranch('')
        setFilter(null)
        reset({
            "device_sl": "",
            "invoice_status": "",
            "invoice_date": "",
            "installment_due_on": "",
            "lease_type": ""
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterApplied) {
            COLLECT_INVOICES_LIST({page: newPage, ...filter})
        } else {
            COLLECT_INVOICES_LIST({page: newPage})
        }
        GET_GARAGE_LIST()
    }

    const makeFilter =(data)=>{
        obj_filters.forEach((item)=>{
            data[item] = data[item]?.value
        })
        if (data.invoice_date) {
            let dateAfterSplit = data.invoice_date.split('-')
            data["created_at_from"] = moment(dateAfterSplit[0]).format("YYYY-MM-DD")
            data["created_at_to"] = moment(dateAfterSplit[1]).format("YYYY-MM-DD")
            delete data['invoice_date']
        }
        if (data.installment_due_on) {
            let dueDateAfterSplit = data.installment_due_on.split('-')
            data["invoice_due_from"] = moment(dueDateAfterSplit[0]).format("YYYY-MM-DD")
            data["invoice_due_to"] = moment(dueDateAfterSplit[1]).format("YYYY-MM-DD")
            delete data['installment_due_on']
        }
        if (borrower) {
            data['borrower_guid'] = formulateApiRequestDataFromSelectedItems(borrower)
        }
        if (garage) {
            data['garage_guid'] = formulateApiRequestDataFromSelectedItems(garage)
        }
        if (financier) {
            data['financier_guid'] = formulateApiRequestDataFromSelectedItems(financier)
        }
        if (branch) {
            data['branch_guid'] = formulateApiRequestDataFromSelectedItems(branch)
        }
    }

    const filterTheList = (data) => {
        makeFilter(data)
        setPage(1)
        setFilter(data)
        setFilterApplied(true)
        COLLECT_INVOICES_LIST({...data, page: 1 })
        GET_GARAGE_LIST()
    }

    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the approved transactions list
            setPage(1)
            setFilter(null)
            COLLECT_INVOICES_LIST({ page: 1 })
            GET_GARAGE_LIST()
        }
        resetFilterValues()
        setFilterApplied(false)
        clearErrors()
    }

    const FilterForm = <>
        <div className={'row'}>
            <div className={'col-md-12'}>
                <Form onSubmit={handleSubmit(filterTheList)}>

                    <div className={'row g-3'}>
                        <div className={'col-md-2'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Smart Battery ID</SolTypography.Text></Form.Label>
                                </div>
                                <SolInput
                                    name={'device_sl'}
                                    type={'number'}
                                    min={'0'}
                                    step={'1'}
                                    placeholder={'Type Smart battery ID'}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                            </Form.Group>
                        </div>

                        <div className={'col-md-2'}>
                            <Form.Group>
                                <Form.Label><SolTypography.Text secondary>Invoice Date</SolTypography.Text></Form.Label>
                                <SolInput
                                    name={'invoice_date'}
                                    readOnly={true}
                                    value={invoiceDateRange}
                                    placeholder={"Select date range"}
                                    ref={register()}
                                    onClick={(e) => {
                                        setTargetForInvoiceDateRangePicker(e.target)
                                        setShowInvoiceDateRangePicker(!showInvoiceDateRangePicker)
                                    }}
                                />
                            </Form.Group>
                        </div>

                        {invoiceDatePopover()}

                        <div className={'col-md-2'}>
                            <Form.Group>
                                <Form.Label><SolTypography.Text secondary>Due Date</SolTypography.Text></Form.Label>
                                <SolInput
                                    name={'installment_due_on'}
                                    readOnly={true}
                                    value={dueDateRange}
                                    placeholder={"Select date range"}
                                    ref={register()}
                                    onClick={(e) => {
                                        setTargetForDueDateRangePicker(e.target)
                                        setShowDueDateRangePicker(!showDueDateRangePicker)
                                    }}
                                />
                            </Form.Group>
                        </div>

                        {invoiceDueDatePopover()}

                        <div className={'col-md-2'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Borrower</SolTypography.Text></Form.Label>
                                </div>
                                <Select
                                    name={'borrower'} // This name is used to show 'n selected item' text
                                    placeholder={"Select Borrower"}
                                    isDisabled={props.collectingBorrowers}
                                    isLoading={props.collectingBorrowers}
                                    maxMenuHeight={200}
                                    value={borrower}
                                    isClearable={true}
                                    options={borrowers}
                                    isSearchable={true}
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    styles={customStylesForMultiSelectField}
                                    components={{ ValueContainer, Option }}
                                    onChange={(selected, { action }) => {
                                        if (action === "clear") {
                                            setBorrower('')
                                        }
                                        if (selected) {
                                            setBorrower(selected)
                                            setBorrowers(groupSelectedOptions(selected, borrowers))
                                        }
                                    }}
                                />
                                {errors.borrower? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.borrower.message}</div></> : null}
                            </Form.Group>
                        </div>

                        <div className={'col-md-2'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Contract Type</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"lease_type"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value,defaultValue, name, ref },
                                             { invalid, isTouched, isDirty }) => (
                                        <Select
                                            name={name}
                                            placeholder={"Select Contract Type"}
                                            maxMenuHeight={200}
                                            value={value}
                                            defaultValue={defaultValue}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={[
                                                { label: "Loan", value: "loan" },
                                                { label: "Lease", value: "lease" },
                                                { label: "Rent", value: "rent"}
                                            ]}
                                            isSearchable={true}
                                            styles={customStylesForSingleSelectField}
                                            onChange={(selected, { action }) => {
                                                if (action === "clear") {
                                                    setContractType('')
                                                }
                                                onChange(selected)
                                                if (selected) {
                                                    setContractType(selected)
                                                }
                                                return selected;
                                            }}
                                        />
                                    )}
                                />
                                {/*{errors.is_settled ? <><div className="text-danger">*/}
                                {/*    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.is_settled.message}</div></> : null}*/}
                            </Form.Group>
                        </div>

                        <div className={'col-md-2'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Payment Status</SolTypography.Text></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"invoice_status"}
                                    rules={{}}
                                    render={({ onChange, onBlur, value,defaultValue, name, ref },
                                             { invalid, isTouched, isDirty }) => (
                                        <Select
                                            name={name}
                                            placeholder={"Select Payment Status"}
                                            maxMenuHeight={200}
                                            value={value}
                                            defaultValue={defaultValue}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={[
                                                { label: "Due", value: "due" },
                                                { label: "Partially Paid", value: "partial_paid"},
                                                { label: "Paid", value: "paid"},
                                                { label: "Overdue", value: "overdue"},
                                                { label: "Cancelled", value: "cancelled"}
                                            ]}
                                            isSearchable={true}
                                            styles={customStylesForSingleSelectField}
                                            onChange={(selected, { action }) => {
                                                if (action === "clear") {
                                                    setPaymentStatus('')
                                                }
                                                onChange(selected)
                                                if (selected) {
                                                    setPaymentStatus(selected)
                                                }
                                                return selected;
                                            }}
                                        />
                                    )}
                                />
                                {/*{errors.is_settled ? <><div className="text-danger">*/}
                                {/*    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.is_settled.message}</div></> : null}*/}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={'row g-3'}>

                        <div className={'col-md-2'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><SolTypography.Text secondary>Garage</SolTypography.Text></Form.Label>
                                </div>
                                <Select
                                    name={'garage'} // This name is used to show 'n item selected' text
                                    placeholder={"Select Garage"}
                                    isDisabled={props.collectingBorrowers}
                                    isLoading={props.collectingBorrowers}
                                    maxMenuHeight={200}
                                    value={garage}
                                    isClearable={true}
                                    options={garages}
                                    isSearchable={true}
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    styles={customStylesForMultiSelectField}
                                    components={{ ValueContainer, Option }}
                                    onChange={(selected, { action }) => {
                                        if (action === "clear") {
                                            setGarage('')
                                        }
                                        if (selected) {
                                            setGarage(selected)
                                            setGarages(groupSelectedOptions(selected, garages))
                                        }
                                    }}
                                />
                                {errors.garage? <><div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garage.message}</div></> : null}
                            </Form.Group>
                        </div>

                        {props.isSolshareUser? <>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label><SolTypography.Text secondary>Financier</SolTypography.Text></Form.Label>
                                    </div>
                                    <Select
                                        name={'financier'} // This name is used to show 'n item selected' text
                                        placeholder={"Select Financier"}
                                        isDisabled={props.collectingFinanciers}
                                        isLoading={props.collectingFinanciers}
                                        maxMenuHeight={200}
                                        value={financier}
                                        isClearable={true}
                                        options={financiers}
                                        isSearchable={true}
                                        isMulti={true}
                                        hideSelectedOptions={false}
                                        styles={customStylesForMultiSelectField}
                                        components={{ ValueContainer, Option }}
                                        onChange={(selected, { action }) => {
                                            if (action === "clear") {
                                                setFinancier('')
                                                // GET_BRANCHES_LIST()
                                            }
                                            setBranch( '')
                                            if (selected) {
                                                setFinancier(selected)
                                                setFinanciers(groupSelectedOptions(selected, financiers))
                                                // GET_BRANCHES_LIST({'organization_guid': formulateApiRequestDataFromSelectedItems(selected)})
                                            }
                                        }}
                                    />
                                    {errors.financier ? <><div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.financier.message}</div></> : null}
                                </Form.Group>
                            </div>
                        </>:null}

                        {/*<div className={'col-md-2'}>*/}
                        {/*    <Form.Group>*/}
                        {/*        <div>*/}
                        {/*            <Form.Label><SolTypography.Text secondary>Branch</SolTypography.Text></Form.Label>*/}
                        {/*        </div>*/}
                        {/*        <Select*/}
                        {/*            name={'branch'} // This name will be used to show 'n items selected' text*/}
                        {/*            placeholder={"Select Branch"}*/}
                        {/*            isDisabled={props.collectingBranches}*/}
                        {/*            isLoading={props.collectingBranches}*/}
                        {/*            maxMenuHeight={200}*/}
                        {/*            value={branch}*/}
                        {/*            isClearable={true}*/}
                        {/*            options={branches}*/}
                        {/*            isSearchable={true}*/}
                        {/*            isMulti={true}*/}
                        {/*            hideSelectedOptions={false}*/}
                        {/*            styles={customStylesForMultiSelectField}*/}
                        {/*            components={{ ValueContainer, Option }}*/}
                        {/*            noOptionsMessage={() => "No branches available"}*/}
                        {/*            onChange={(selected, { action }) => {*/}
                        {/*                if (action === "clear") {*/}
                        {/*                    setBranch('')*/}
                        {/*                }*/}
                        {/*                if (selected) {*/}
                        {/*                    setBranch(selected)*/}
                        {/*                    setBranches(groupSelectedOptions(selected, branches))*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*        {errors.branch ? <><div className="text-danger">*/}
                        {/*            <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.branch.message}</div></> : null}*/}
                        {/*    </Form.Group>*/}
                        {/*</div>*/}

                    </div>
                    <div className={'row'}>
                        <div className={"col-md-12"}>
                            <Button variant="warning" size={'sm'} type="submit"
                                    disabled={props.listLoading}>
                                Apply Filter
                            </Button>
                            <Button variant="outline-dark" size={'sm'} onClick={() => { resetFilter() }}
                                    className={'ml-3'}>
                                Reset Filter
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <hr /></>


    // ------------- Begin: Bulk invoice extension related  --------------- //
    const initiateBulkExtensionProcess = () => {
        setShowInvoiceExtensionModal(true)
    }

    const stopBulkExtensionProcess = () => {
        setShowInvoiceExtensionModal(false)
    }

    const renderBulkInvoiceExtensionModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={stopBulkExtensionProcess}
                modal={showInvoiceExtensionModal}
                title={<h1><strong>Bulk Invoices Extension</strong></h1>}>
                <BulkInvoiceExtension handleExtensionProcess={extendInvoices} cancelProcess={stopBulkExtensionProcess}/>
            </GenericModal>
        </>
    }

    const extendInvoices = (invoiceStatus, payload) => {
        if (invoiceStatus === 'overdue') {
            EXTEND_OVERDUE_INVOICES(payload)
        } else {
            EXTEND_UPCOMING_INSTALLMENTS(payload)
        }
    }

    useEffect(() => {
        if ((props.invoiceExtended && props.invoiceExtended === requestCycle.success) || (props.installmentExtended && props.installmentExtended === requestCycle.success)) {
            toast.success("Bulk invoice extension process is successful!")
            COLLECT_INVOICES_LIST({page: page, ...filter})
            setShowInvoiceExtensionModal(false)
        }
    }, [props.invoiceExtended, props.installmentExtended])

    useEffect(() => {
        if (props.errorMessageForInvoiceExtension) {
            showNotifications('error', props.errorMessageForInvoiceExtension)
        }
    }, [props.errorMessageForInvoiceExtension])

    useEffect(() => {
        if (props.errorMessageForInstallmentExtension) {
            showNotifications('error', props.errorMessageForInstallmentExtension)
        }
    }, [props.errorMessageForInstallmentExtension])
    // ------------- End: Bulk invoice extension related  --------------- //

    return (
        <ContentWrapper isLoading={false} pageTitle={"Mobile Application Events"} showBackButton={false} showCardHeader={false}>

            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>Invoices</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            <IconButton onClick={filterToggle}>
                                <FilterListIcon color="disabled" fontSize="large" />
                            </IconButton>
                            <IconButton
                                onClick={invoicesWithoutPagination}
                                disabled={props.collectingInvoicesForExport || props.collectingInvoices}>
                                {props.collectingInvoicesForExport? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>:
                                    <img src={require('../../../utils/asset/download-Button.svg')} alt="Download" />}
                            </IconButton>
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr />
            </div>

            {showFilter ?
                FilterForm : null}

            <div className={isTabletOrMobile ? 'row' : 'row mt-7'}>
                <div className={'col-md-4'}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <Avatar alt={'TK'} src={require('../../../utils/asset/calendar-grid.svg')} variant={'square'} />
                        <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                            <div><h5 style={{ "display": "inline" }}> <b>{`TK ${new Intl.NumberFormat('en-IN').format(props.invoices?.total_amount?.toFixed(2) || 0)} / `}</b> </h5> <h6 style={{ "display": "inline", "color": "green" }}>{`TK ${new Intl.NumberFormat('en-IN').format(props.invoices?.total_paid?.toFixed(2) || 0)}`}</h6></div>
                            <><small>Cumulative Revenue (Total / Paid)</small></>
                        </Stack>
                    </Stack>
                </div>
                <div className={isTabletOrMobile ? 'col-md-4 mt-5' : 'col-md-4'}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        spacing={1}
                    >
                        <Avatar alt={'TOTAL'} src={require('../../../utils/asset/calculator-tick.svg')} variant={'square'} />
                        <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                            <div><h5 style={{ "display": "inline" }}> <b>{`TK ${new Intl.NumberFormat('en-IN').format(props.invoices?.total_due?.toFixed(2) || 0)}`}</b> </h5></div>
                            <><small>Total Due Amount</small></>
                        </Stack>
                    </Stack>
                </div>
                <>
                    <div className={isTabletOrMobile ? 'col-md-4 mt-5' : 'col-md-4'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                            spacing={1}
                        >
                            <Avatar alt={'ACTIVE'} src={require('../../../utils/asset/warning-exclamation.svg')} variant={'square'} />
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <div><h5 style={{ "display": "inline" }}> <b>{`TK ${Intl.NumberFormat('en-IN').format(props.invoices?.total_overdue?.toFixed(2) || 0)}`}</b> </h5></div>
                                <><small>Total Overdue Amount</small></>
                            </Stack>
                        </Stack>
                    </div>
                </>
            </div>
            <br />
            <br />

            <div>
                <DataTableContainer>
                    {!props.collectingInvoices && invoices? <DataTable
                        noDataAvailableMessageInEnglish={"No invoices available"}
                        language={language}
                        columns={invoicesColumn}
                        isLoading={props.collectingInvoices}
                        data={invoices}
                        asyncPagination={true}
                        count={props.invoices?.count}
                        itemsPerPage={props.invoices?.page_size}
                        onChangePage={onChangePage}
                        page={page}
                    /> : <LoadingSpinner language={language} loadingSubText={"Collecting invoices ..."} />}
                </DataTableContainer>

            </div>

            {renderBulkInvoiceExtensionModal()}
        </ContentWrapper>
    );
};

Invoices.propTypes = {

};

export default connect((state) => {
    return {
        authorization: state.auth.authorizations,
        language: state.auth.language,
        roleName: state.auth.role,
        isSolshareUser: state.auth.is_solshare_user,
        isLoading: state.invoicesReducer.isLoading,
        collectingBorrowers: state.commonReducer.collectingBorrowers,
        borrowers: state.commonReducer.borrowers,
        garages: state.commonReducer.garages,
        collectingFinanciers: state.invoicesReducer.collectingFinanciers,
        financiers: state.invoicesReducer.financiers,
        collectingInvoices: state.invoicesReducer.collectingInvoices,
        invoices: state.invoicesReducer.invoices,
        collectingTransactionsForExport: state.invoicesReducer.collectingTransactionsForExport,
        settlementPaymentsForExport: state.invoicesReducer.settlementPaymentsForExport,
        invoicesForExport: state.invoicesReducer.invoicesForExport,
        collectingInvoicesForExport: state.invoicesReducer.collectingInvoicesForExport,
        branches: state.commonReducer.branches,
        collectingBranches: state.commonReducer.collectingBranches,
        invoiceExtended: state.invoicesReducer.invoiceExtended,
        errorMessageForInvoiceExtension: state.invoicesReducer.errorMessageForInvoiceExtension,
        installmentExtended: state.invoicesReducer.installmentExtended,
        errorMessageForInstallmentExtension: state.invoicesReducer.errorMessageForInstallmentExtension
    }
}, { ...transactionActions, ...commonActions })(Invoices);