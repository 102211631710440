import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE: ()=>({
        [CALL_API]: {
            endpoint: '/dashboard_summary',
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_REQUEST,
                actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_SUCCESS,
                actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_FAILURE,
            ]
        }
    }),

    COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE: ()=>({
        [CALL_API]: {
            endpoint: '/dashboard_summary',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_REQUEST,
                actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_SUCCESS,
                actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
