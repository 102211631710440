import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";

/*Initial State for grid*/
const initialState= {
    summaryDataFromGarage: undefined,
    summaryDataCollectionErrorFromGarage: undefined,
    summaryDataFromTransaction: undefined,
    summaryDataCollectionErrorFromTransaction: undefined
};

/*Reducer for Loan's redux store*/
export const overviewReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_REQUEST:
            return {
                ...state,
                summaryDataFromGarage: undefined,
                summaryDataCollectionErrorFromGarage: undefined,
                collectingSummaryDataFromGarage: true
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_SUCCESS:
            return {
                ...state,
                summaryDataFromGarage: action.payload,
                collectingSummaryDataFromGarage: false
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FAILURE:
            return {
                ...state,
                summaryDataCollectionErrorFromGarage: action.payload.error,
                collectingSummaryDataFromGarage: false
            };

        case actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_REQUEST:
            return {
                ...state,
                summaryDataFromTransaction: undefined,
                summaryDataCollectionErrorFromTransaction: undefined,
                collectingSummaryDataFromTransaction: true
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_SUCCESS:
            return {
                ...state,
                summaryDataFromTransaction: action.payload,
                collectingSummaryDataFromTransaction: false
            };
        case actionTypes.COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_FAILURE:
            return {
                ...state,
                summaryDataCollectionErrorFromTransaction: action.payload.error,
                collectingSummaryDataFromTransaction: false
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
