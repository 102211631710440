import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import Overview from "./views/overview";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";
import SecuredRoute from "../../router/SecuredRoute";


export const OverviewRouter =(props)=> {

    return (
        <Switch>
            {/* Routes to overview*/}

            {/* Garage dashboard overview access is enough as "Total Batteries Deployed" card which represents data
             from Garage Service, will remain always visible, if user has access to overview page. Access to transaction
             overview access doesn't matter here - Noor Reza, 16th Jan, 2024 10:21 pm */}
            <SecuredRoute
                path={"/overview"}
                component={Overview}
                service={getServiceName('garageService')}
                feature={getFeatureName('dashboardOverviewFeatureForGarage')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
