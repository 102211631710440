import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Controller, useForm} from "react-hook-form";
import {Button, Form, InputGroup, OverlayTrigger, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {DatePicker} from '@material-ui/pickers/'
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {transactionActions as actions} from "../../actions";
import {connect} from "react-redux";
import {upazillas} from "../../../../utils/countryData/3dListBangladesh";
import {toast} from "react-toastify";
import {requestCycle} from "../../utils";
import * as bdData from "../../../../utils/countryData/3dListBangladesh";
import {devicesReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {isValidPhoneNumber} from 'libphonenumber-js';
import {useSubheader} from "../../../../../_metronic/layout";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
const CreateTransaction = props => {
    const {register, errors, control, handleSubmit, clearErrors} = useForm();
    const [language, setLanguage] = useState("EN");
    const subHeader = useSubheader();

    const [paidAmount, setPaidAmount] = useState("");
    const [paidAmountInputIsValid, setPaidAmountInputIsValid] = useState(false);
    const [paidAmountZeroError, setPaidAmountZeroError] = useState(false);
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false)

    const [errorMessage, setErrorMessage] = useState(false)

    const [inputElement, setInputElement] = useState(null)

    const products = [
        {
            label: "Product 1",
            value: "1"
        },
        {
            label: "Product 2",
            value: "2"
        },
        {
            label: "Product 3",
            value: "3"
        }
    ]

    // let inputElement=null;
    let jqueryScript = document.createElement('script');
    let bkashScript = document.createElement('script');
    let checkout = document.createElement('script');

    const payWithBkash = (data) => {
        changeScript(data.product.value, paidAmount)
        inputElement.click();
    }

    // useEffect(() => {
    //
    //
    //     jqueryScript.src = "https://code.jquery.com/jquery-3.3.1.min.js";
    //     jqueryScript.integrity = "sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8=";
    //     jqueryScript.crossOrigin = "anonymous";
    //     jqueryScript.async = true;
    //     bkashScript.src = "https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js";
    //     bkashScript.async = true;
    //     bkashScript.id = 'bkash_provided_script'
    //     bkashScript.id = 'bkash_provided_script'
    //
    //
    //
    //     return () => {
    //         document.body.removeChild(jqueryScript);
    //         document.body.removeChild(bkashScript);
    //         document.body.removeChild(checkout);
    //     }
    // }, []);

    const changeScript=(productId, amount)=>{
        let  paymentID = '';
        if (document.getElementById('bkash_check_script')){
            window.location.reload();
            // document.getElementById('bkash_check_script').remove()
        }
        // bkashScript.src = "https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js";
        // const amount= 40;
        checkout.text = "var paymentID = '';\n" +
            "\n" +
            "bKash.init({\n" +
            "\n" +
            "    paymentMode: 'checkout', //fixed value ‘checkout’\n" +
            "\n" +
            "    //paymentRequest format: {amount: AMOUNT, intent: INTENT}\n" +
            "\n" +
            "    //intent options\n" +
            "\n" +
            "    //1) ‘sale’ – immediate transaction (2 API calls)\n" +
            "\n" +
            "    //2) ‘authorization’ – deferred transaction (3 API calls)\n" +
            "\n" +
            "    paymentRequest: {\n" +
            "\n" +
            "        \"pay_channel_type\": \"APP\",\n" +
            "\n" +
            "        \"product_type\": \"Dongle\",\n" +
            "\n" +
            "        \"transaction_status\": \"pending\",\n" +
            "\n" +
            "        \"device_serial_number\": \"10001\",\n" +
            "\n" +
            "        \"provider_name\": \"bkash\",\n" +
            "\n" +
            "        \"product_id\": \"" + productId + "\", //max two decimal points allowed\n" +
            "\n" +
            "        \"amount\": \"" + amount + "\", //max two decimal points allowed\n" +
            "\n" +
            "        \"intent\": \"sale\"\n" +
            "\n" +
            "    },\n" +
            "\n" +
            "\n" +
            "    createRequest: function (request) { //request object is basically the paymentRequest object, automatically pushed by the script in createRequest method\n" +
            "\n" +
            "        $.ajax({\n" +
            "\n" +
            "            url: \'" + process.env.REACT_APP_BACKEND_URL_TRANSACTION_MANAGEMENT + "/transactions\',\n" +
            "\n" +
            "            type: 'POST',\n" +
            "\n" +
            "            contentType: 'application/json',\n" +
            "\n" +
            "            headers: {\n" +
            "\n" +
            "                \"Authorization\": \"Bearer " + props.token + "\",\n" +
            "\n" +
            "                'Content-Type': 'application/json;'\n" +
            "\n" +
            "            },\n" +
            "\n" +
            "            data: JSON.stringify(request),\n" +
            "\n" +
            "            success: function (data) {\n" +
            "\n" +
            "                console.log(data)\n" +
            "\n" +
            "                //   data = JSON.parse(data);\n" +
            "\n" +
            "                if (data && data.paymentID != null) {\n" +
            "\n" +
            "                    paymentID = data.paymentID;\n" +
            "\n" +
            "                    bKash.create().onSuccess(data);\n" +
            "\n" +
            "                    console.log('successfully created') //pass the whole response data in bKash.create().onSucess() method as a parameter\n" +
            "\n" +
            "                } else {\n" +
            "\n" +
            "                    bKash.create().onError();\n" +
            "\n" +
            "                }\n" +
            "\n" +
            "            },\n" +
            "\n" +
            "            error: function () {\n" +
            "\n" +
            "                bKash.create().onError();\n" +
            "\n" +
            "            }\n" +
            "\n" +
            "        });\n" +
            "\n" +
            "    },\n" +
            "    onClose : function () {\n" +
            "        //define what happens if the user closes the pop up window\n" +
            "        window.location.reload()\n" +
            "        //your code goes here\n" +
            "    },\n" +
            "\n" +
            "    executeRequestOnAuthorization: function () {\n" +
            "\n" +
            "        $.ajax({\n" +
            "\n" +
            "            url: \'" + process.env.REACT_APP_BACKEND_URL_TRANSACTION_MANAGEMENT + "/transactions/execute-payment\',\n" +
            "\n" +
            "            type: 'POST',\n" +
            "\n" +
            "            contentType: 'application/json',\n" +
            "\n" +
            "            headers: {\n" +
            "\n" +
            "                \"Authorization\": \"Bearer " + props.token + "\",\n" +
            "\n" +
            "                'Content-Type': 'application/json;'\n" +
            "\n" +
            "            },\n" +
            "\n" +
            "            data: JSON.stringify({\n" +
            "\n" +
            "                \"paymentID\": paymentID\n" +
            "\n" +
            "            }),\n" +
            "\n" +
            "            success: function (data) {\n" +
            "\n" +
            "\n" +
            "                if (data && data.paymentID != null && data.transactionStatus == \"Completed\") {\n" +
            "\n" +
            "                    //Merchant’s success page\n" +
            "                    setInterval(function () {  window.location.href = \"https://www.emobility-stage.me-solshare.net/transaction/create\"; }, 5000);\n" +
            "\n" +
            "                    bKash.execute().onError();\n" +
            "                    Swal.fire({\n" +
            "                        icon: 'success',\n" +
            "                        title: 'Payment Successful',\n" +
            "                        timer: 6000,\n" +
            "                        // text: data['errorMessage'],\n" +
            "                    })\n" +
            "\n" +
            "\n" +
            "                } else {\n" +
            "\n" +
            "                    bKash.execute().onError();\n" +
            "                    Swal.fire({\n" +
            "                        icon: 'error',\n" +
            "                        title: 'Payment Failed',\n" +
            "                        timer: 6000,\n" +
            "                        text: data['errorMessage'],\n" +
            "\n" +
            "                    })\n" +
            "\n" +
            "                }\n" +
            "\n" +
            "            },\n" +
            "\n" +
            "            error: function () {\n" +
            "                bKash.execute().onError();\n" +
            "                Swal.fire({\n" +
            "                    icon: 'error',\n" +
            "                    title: 'Payment Failed',\n" +
            "                    timer: 6000,\n" +
            "                    text: \"Something went wrong\",\n" +
            "\n" +
            "                })\n" +
            "\n" +
            "            }\n" +
            "\n" +
            "        });\n" +
            "\n" +
            "    }\n" +
            "\n" +
            "});\n"
        checkout.id = 'bkash_check_script'
        checkout.async = false;
        document.body.appendChild(checkout);
    }

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setActionButtons(null)
    }, [])

    console.log(inputElement)

    const getErrorMessage=()=>{
        return errors.paidAmount.type==="maxLength"? "Maximum 4 digits allowed": "something is wrong"
    }

    return (
        <ContentWrapper showCardHeader={false} pageTitle={'Register user'} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><b>{language === 'EN'? 'Payment using bKash':'বিকাশে পরিশোধ'}</b></h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Form onSubmit={handleSubmit(payWithBkash)}>
                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group >
                                    <Form.Label>{language === 'EN'? 'Product':'প্রোডাক্ট'}<span className="required text-danger">*</span></Form.Label>
                                    <Controller
                                        control={control}
                                        name="product"
                                        rules= {{ required: language === "EN"? "Please select a product first!":"দয়া করে আগে একটি প্রোডাক্ট বাছাই করুন!" }}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                name={'product'}
                                                placeholder={language === 'EN'? 'Select a product':'একটি প্রোডাক্ট বাছাই করুন'}
                                                classNamePrefix="react-select-sol-style"
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={products}
                                                isSearchable={true}
                                                onChange={(selected, {action}) =>{
                                                    if (action === "clear") {
                                                        setPaidAmount("")
                                                    }
                                                    onChange(selected)
                                                    if (selected && selected.value === '1') {
                                                        setPaidAmount(1)
                                                    } else if (selected && selected.value === '2') {
                                                        setPaidAmount(2)
                                                    } else if (selected && selected.value === '3') {
                                                        setPaidAmount(3)
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    {errors.product && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.product.message}</div>}
                                </Form.Group>
                            </div>

                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-6"}>
                                <Form.Group >
                                    <Form.Label>{language === 'EN'? 'Amount (in BDT)':'পরিমাণ (টাকায়)'}<span className="required text-danger">*</span></Form.Label>
                                    <InputGroup>
                                        <SolInput
                                            type="text"
                                            name={"paidAmount"}
                                            placeholder={language === 'EN'? "Amount to be paid via bKash for the selected product":"বাছাইকৃত প্রোডাক্টের জন্য বিকাশে যে পরিমাণ অর্থ পরিশোধ করা হচ্ছে"}
                                            value={paidAmount}
                                            disabled={true}
                                        />
                                    </InputGroup>
                                    {errors.paidAmount? <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.paidAmount.message}</div>:null}
                                </Form.Group>
                            </div>

                        </div>

                        <div className={'row g-3'}>
                            <div className={"col-md-12 no-padding"}>
                                <Button style={{"background-color": "#DD3E73"}}  type="submit" size={'sm'} className={"ml-3"}>
                                    <img src={require('../../bKash-01.svg')} alt={"logo"} height={20} width={20}/>&nbsp;{language === 'EN'? 'Pay using bKash':'বিকাশে পরিশোধ করুন'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <input id={'bKash_button'} ref={input => setInputElement(input)} hidden={true}/>
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
        </ContentWrapper>
    );
};

CreateTransaction.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        language: state.auth.language,
        errorMessage: state.checkoutReducer.errorMessage,
        token: state.auth.access_token
    }
}

export default connect(mapStateToProps, actions) (CreateTransaction);
