import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_DEPOSITS: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/topup', filter),
            backend_slug: 'transaction_management',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DEPOSITS_REQUEST,
                actionTypes.COLLECT_DEPOSITS_SUCCESS,
                actionTypes.COLLECT_DEPOSITS_FAILURE,
            ]
        }
    }),

    COLLECT_DEPOSITS_FOR_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/topup', filter),
            backend_slug: 'transaction_management',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_REQUEST,
                actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_SUCCESS,
                actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    COLLECT_DEPOSITS_DETAILS: (depositGuid)=>({
        [CALL_API]: {
            endpoint: '/cash_payment/details/' + depositGuid,
            backend_slug: 'transaction_management',
            method: 'GET',
            types: [
                actionTypes.COLLECT_DEPOSITS_DETAILS_REQUEST,
                actionTypes.COLLECT_DEPOSITS_DETAILS_SUCCESS,
                actionTypes.COLLECT_DEPOSITS_DETAILS_FAILURE,
            ]
        }
    }),

    COLLECT_GARAGE_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/garages',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_LIST_REQUEST,
                actionTypes.COLLECT_GARAGE_LIST_SUCCESS,
                actionTypes.COLLECT_GARAGE_LIST_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
