import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    deposits: undefined,
    errorMessageRegardingDepositCollection: undefined,
    depositsForExport: undefined,
    errorMessageRegardingDepositExport: undefined,
    depositDetails: undefined,
    errorMessageForDepositDetailsCollection: undefined,
    garagesToShowUpdatedInfo: undefined
};

/*Reducer for Borrower's redux store*/
export const depositsReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.COLLECT_DEPOSITS_REQUEST:
            return {
                ...state,
                deposits: undefined,
                errorMessageRegardingDepositCollection: undefined,
                collectingDeposits: true
            };
        case actionTypes.COLLECT_DEPOSITS_SUCCESS:
            return {
                ...state,
                deposits: action.payload,
                collectingDeposits: false
            };
        case actionTypes.COLLECT_DEPOSITS_FAILURE:
            return {
                ...state,
                errorMessageRegardingDepositCollection: action.payload.error,
                collectingDeposits: false
            };

        case actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_REQUEST:
            return {
                ...state,
                depositsForExport: undefined,
                errorMessageRegardingDepositExport: undefined,
                collectingDepositsForExport: true
            };
        case actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                depositsForExport: action.payload,
                collectingDepositsForExport: false
            };
        case actionTypes.COLLECT_DEPOSITS_FOR_EXPORT_FAILURE:
            return {
                ...state,
                errorMessageRegardingDepositExport: action.payload.error,
                collectingDepositsForExport: false
            };

        case actionTypes.COLLECT_DEPOSITS_DETAILS_REQUEST:
            return {
                ...state,
                depositDetails: undefined,
                errorMessageForDepositDetailsCollection: undefined,
                collectingDepositDetails: true
            };
        case actionTypes.COLLECT_DEPOSITS_DETAILS_SUCCESS:
            return {
                ...state,
                depositDetails: action.payload,
                collectingDepositDetails: false
            };
        case actionTypes.COLLECT_DEPOSITS_DETAILS_FAILURE:
            return {
                ...state,
                errorMessageForDepositDetailsCollection: action.payload.error,
                collectingDepositDetails: false
            };

        case actionTypes.COLLECT_GARAGE_LIST_REQUEST:
            return {
                ...state,
                garagesToShowUpdatedInfo: undefined
            };
        case actionTypes.COLLECT_GARAGE_LIST_SUCCESS:
            return {
                ...state,
                garagesToShowUpdatedInfo: action.payload
            };
        case actionTypes.COLLECT_GARAGE_LIST_FAILURE:
            return {
                ...state
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
