export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_REQUEST: 'COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_REQUEST',
    COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_SUCCESS: 'COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_SUCCESS',
    COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FAILURE: 'COLLECT_SUMMARY_INFO_FROM_GARAGE_SERVICE_FAILURE',

    COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_REQUEST: 'COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_REQUEST',
    COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_SUCCESS: 'COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_SUCCESS',
    COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_FAILURE: 'COLLECT_SUMMARY_INFO_FROM_TRANSACTION_SERVICE_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',
};
