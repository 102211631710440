export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    COLLECT_DEPOSITS_REQUEST: 'COLLECT_DEPOSITS_REQUEST',
    COLLECT_DEPOSITS_SUCCESS: 'COLLECT_DEPOSITS_SUCCESS',
    COLLECT_DEPOSITS_FAILURE: 'COLLECT_DEPOSITS_FAILURE',

    COLLECT_DEPOSITS_FOR_EXPORT_REQUEST: 'COLLECT_DEPOSITS_FOR_EXPORT_REQUEST',
    COLLECT_DEPOSITS_FOR_EXPORT_SUCCESS: 'COLLECT_DEPOSITS_FOR_EXPORT_SUCCESS',
    COLLECT_DEPOSITS_FOR_EXPORT_FAILURE: 'COLLECT_DEPOSITS_FOR_EXPORT_FAILURE',

    COLLECT_DEPOSITS_DETAILS_REQUEST: 'COLLECT_DEPOSITS_DETAILS_REQUEST',
    COLLECT_DEPOSITS_DETAILS_SUCCESS: 'COLLECT_DEPOSITS_DETAILS_SUCCESS',
    COLLECT_DEPOSITS_DETAILS_FAILURE: 'COLLECT_DEPOSITS_DETAILS_FAILURE',

    COLLECT_GARAGE_LIST_REQUEST: 'COLLECT_GARAGE_LIST_REQUEST',
    COLLECT_GARAGE_LIST_SUCCESS: 'COLLECT_GARAGE_LIST_SUCCESS',
    COLLECT_GARAGE_LIST_FAILURE: 'COLLECT_GARAGE_LIST_FAILURE',

    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE'
};
