import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Badge, Breadcrumb, Button, Form, OverlayTrigger, Spinner, Popover} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import {batteryLogReducer} from "../../reducer";
import {DayPickerRangeController} from "react-dates";
import moment from "moment";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";
import {exportInExcel} from "../../../../utils/excelExport";
import InfoIcon from "@mui/icons-material/Info";
import Overlay from "react-bootstrap/Overlay";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const BatteryLogs = (props) => {
    const [showFilter, setShowFilter] = useState(true);
    const [filterCriteria, setFilterCriteria] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [invalidBatterySerial, setInvalidBatterySerial] = useState(false);
    const [invalidDongleSerial, setInvalidDongleSerial] = useState(false);

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [showTimeSelectionDateRangePicker, setShowTimeSelectionDateRangePicker] = useState(false);
    const [targetForTimeSelectionDateRangePicker, setTargetForTimeSelectionDateRangePicker] = useState(null);

    const [batterySerial, setBatterySerial] = useState('');
    const [dongleSerial, setDongleSerial] = useState('');
    const [capacities, setCapacities] = useState([]);
    const [selectedCapacity, setSelectedCapacity] = useState(null);
    const [voltages, setVoltages] = useState([]);
    const [selectedVoltage, setSelectedVoltage] = useState(null);

    const [batteryLog, setBatteryLog] = useState([]);
    const [language, setLanguage] = useState('EN');
    const [authorization, setAuthorization] = useState(null);
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const subHeader = useSubheader();

    const generalRequiredMessage = language === 'EN'? 'Please provide at least one of the above information!':'দয়া করে অন্তত একটি তথ্য দিন!'

    {/* TODO: Link to the battery graph only when user have the permission ?*/}
    const batteryLogColumns = [
        {
            field: 'battery_sl',
            title: language === 'EN'? 'ID of the battery':'ব্যাটারী আইডি',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            render: rowData => <Link style={{color: "#F18D00"}}
                                     target={'_blank'}
                                     to={`/batteries/battery_graph/${rowData.battery_sl}`}>
                {rowData.battery_sl}
            </Link>
            // disableClick: true,
        },
        {
            field: 'generated_at',
            title: language === 'EN'? 'Time and date stamp':'সময় এবং তারিখ',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true,
            render: rowData => <span className={"text-nowrap"}>{rowData.generated_at}</span>
        },
        {
            field: 'ID_0002',
            title: language === 'EN'? 'Battery voltage (V)':'ব্যাটারী ভোল্টেজ (V)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'ID_0003',
            title: language === 'EN'? 'Battery current (A)':'ব্যাটারীর তড়িৎ (A)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'ID_0004',
            title: 'SOC (%)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'ID_0051',
            title: 'Lifetime charge (Ah_in)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'ID_0052',
            title: language === 'EN'? 'Lifetime discharge (Ah_out)':'এখন পর্যন্ত হওয়া ডিসচার্জ (Ah_out)',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'ID_0052',
            title: language === 'EN'? 'Approx. km run':'এখন পর্যন্ত যাওয়া কি.মি.',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        }
    ]

    const {
        GET_BATTERY_LOG
    } = props;
    const classes = useStylesIconButton();

    const clearAllErrorMessages = () => {
        setRequiredErrorMessage(false)
    }

    const resetFilter = () => {
        resetFilterValues()
        clearAllErrorMessages()
        if (filterApplied) {
            // Filter will always be applied
            // Resetting the battery log
            setPage(1)
            collectLastTwoDaysData()
            setFilterApplied(false)
        }
    }

    const resetFilterValues = () => {
        setBatterySerial('')
        setDongleSerial('')
        setSelectedCapacity('')
        setSelectedVoltage('')
    }

    const filterTheList = () => {
        if (!batterySerial && !dongleSerial && !(start && end) && !selectedCapacity && !selectedVoltage) {
            setRequiredErrorMessage(true)
        } else {
            setFilterApplied(true)
            let filter = {log_type: "warranty_log", page: 1}
            if (start && end) {
                let from_date = language === 'EN'? moment(start).format("YYYY-MM-DD"):convertToAnotherLang(moment(start).format("YYYY-MM-DD"))
                let to_date = language === 'EN'? moment(end).format("YYYY-MM-DD"):convertToAnotherLang(moment(end).format("YYYY-MM-DD"))
                filter['from_date'] = from_date
                filter['to_date'] = to_date
            }
            if (selectedCapacity) {
                filter['battery_capacity'] = selectedCapacity.value
            }
            if (selectedVoltage) {
                filter['battery_voltage'] = selectedVoltage.value
            }
            if (batterySerial) {
                filter['battery_serial_number'] = batterySerial
            }
            if (dongleSerial) {
                filter['dongle_serial_number'] = dongleSerial
            }
            GET_BATTERY_LOG(filter)
        }
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const collectLastTwoDaysData = () => {
        let todaysDate = moment().format("YYYY-MM-DD")
        let twoDaysEarlier = moment(todaysDate).subtract(1, 'd').format("YYYY-MM-DD")
        setStartDate(moment(twoDaysEarlier))
        setEndDate(moment(todaysDate))
        let from_date = props.language === 'EN'? twoDaysEarlier:convertToAnotherLang(twoDaysEarlier)
        let to_date = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        GET_BATTERY_LOG({from_date: from_date, to_date: to_date, log_type: "warranty_log", page: 1})
        setFilterApplied(true)
        // TODO: Need to show the following text
        // setFilterText(language === 'EN'? 'between dates ' + moment(todaysDate).format("DD MMM,YYYY") + ' and ' +
        //     moment(todaysDate).format("DD MMM,YYYY"):moment(todaysDate).format("DD MMM,YYYY") + ' এবং ' +
        //     moment(todaysDate).format("DD MMM,YYYY") + ' তারিখের মাঝে')
    }

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        subHeader.setActionButtons(null)
        subHeader.setBreadCrumbComponent(null)
        collectLastTwoDaysData()

        setCapacities([{
            label: '80',
            value: 80
        }, {
            label: '100',
            value: 100
        }, {
            label: '120',
            value: 120
        }])

        setVoltages([{
            label: '51.2',
            value: 51.2
        }, {
            label: '64',
            value: 64
        }])

        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }
    }, []);


    useEffect(() => {
        if (props.batteryLogs) {
            const batteryLogs = props.batteryLogs;
            if (batteryLogs && batteryLogs.length > -1) {
                setBatteryLog(batteryLogs.map((log, index) => {
                    return {
                        ...log,
                        battery_sl: log.battery_sl,
                        generated_at: moment(log.generated_at).format("DD MMM, YYYY hh:mm:ss a"),
                        ID_0002: log.ID_0002 && log.ID_0002 !== 'N/A'? (log.ID_0002/1000).toFixed(1):log.ID_0002,
                        ID_0003: log.ID_0003 && log.ID_0003 !== 'N/A'? (log.ID_0003/1000).toFixed(1):log.ID_0003,
                        ID_0051: log.ID_0051 && log.ID_0051 !== 'N/A'? (log.ID_0051/1000).toFixed(1):log.ID_0051,
                        ID_0052: log.ID_0052 && log.ID_0052 !== 'N/A'? (log.ID_0052/1000).toFixed(1):log.ID_0052
                    }
                }))
            }
        } else {
            setBatteryLog([])
        }
    }, [props.batteryLogs]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let filter = {log_type: "warranty_log", page: newPage}
        if (filterCriteria && searchText) {
            filter[filterCriteria.value] = searchText
            GET_BATTERY_LOG(filter)
        } else {
            GET_BATTERY_LOG(filter);
        }
    }

    const viewBatteryLog = (rowData) => {
        props.history.push({
            pathname: `/batteries/battery_graph/${rowData.battery_sl}`,
            purpose: 'details',
            batteryData: rowData
        })
    }


    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    }, [start, end])

    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [clickCount, setClickCount] = useState(0)
    const onDatesChange = ({startDate, endDate}) => {
        setClickCount(clickCount + 1)
        if (startDate && endDate) {
            setStartDate(startDate)
            setEndDate(endDate)
            if (clickCount === 1) {
                setClickCount(0)
                setShowTimeSelectionDateRangePicker(false)
            }
        } else if (startDate && !endDate) {
            setStartDate(startDate)
            setEndDate(startDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const showHelpfulText = () => {
        return <>
            <div className={'container'} style={{color: "#F18D00"}}><InfoIcon size={'sm'}/> Selection is completed
                after <strong>two clicks on a single date</strong> or <strong>single click on two dates.</strong></div>
        </>
    }

    const timeSelectionPopover = () => {
        return <>
            <Overlay
                show={showTimeSelectionDateRangePicker}
                target={targetForTimeSelectionDateRangePicker}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={start}
                            endDate={end}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            isOutsideRange={disableFutureDt}
                            onDatesChange={onDatesChange}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "timeSelection") {
                                    setShowTimeSelectionDateRangePicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    const exportData = () => {
        let title = language === 'EN'? 'Battery log':'ব্যাটারী লগ'
        let excelRows = [
            [title, "", "", "", "", ""],
            ["", "", "", "", "", ""],
            ["", "", "", "", "", ""],
            [batteryLogColumns[0].title, batteryLogColumns[1].title, batteryLogColumns[2].title,
                batteryLogColumns[3].title, batteryLogColumns[4].title, batteryLogColumns[5].title]
        ]
        if (filterApplied) {
            if (start && end) {
                let filterText = language === 'EN'? "From " + moment(start).format("Do MMMM,YYYY") + " 00:00:00 am to " +
                    moment(end).format("Do MMMM,YYYY") + ' 23:59:59 pm':moment(start).format("Do MMMM,YYYY") +
                    " ০০ঃ০০ঃ০০ থেকে " + moment(end).format("Do MMMM,YYYY") + " ২৩ঃ৫৯ঃ৫৯ পর্যন্ত"
                excelRows.splice(1, 0, [filterText, "", "", "", "", ""])
            }
            if (batterySerial) {
                let filterText = language === 'EN'? "Battery: " + batterySerial:"ব্যাটারী: " + batterySerial
                excelRows.splice(1, 0, [filterText, "", "", "", "", ""])
            }
            if (dongleSerial) {
                let filterText = language === 'EN'? "Dongle: " + dongleSerial:"ডঙ্গল: " + dongleSerial
                excelRows.splice(1, 0, [filterText, "", "", "", "", ""])
            }
            if (selectedCapacity) {
                let filterText = language === 'EN'? "Capacity: " + selectedCapacity.label:"ক্ষমতা: " + selectedCapacity.label
                excelRows.splice(1, 0, [filterText, "", "", "", "", ""])
            }
            if (selectedVoltage) {
                let filterText = language === 'EN'? "Voltage: " + selectedVoltage.label + "V":"ভোল্টেজ: " + selectedVoltage.label + "V"
                excelRows.splice(1, 0, [filterText, "", "", "", "", ""])
            }
        }
        for (let i=0; i<batteryLog.length; i++) {
            // Pushing into the Excel
            excelRows.push([batteryLog[i].battery_sl, batteryLog[i].generated_at,
                batteryLog[i]['ID_0002'], batteryLog[i]['ID_0003'], batteryLog[i]['ID_0052'],
                batteryLog[i]['ID_0052']])
        }
        if (batteryLog.length === 0) {
            let noDataAvailableText =  language === 'EN'? "No history available":"কোন তথক্স নেই"
            excelRows.push(["", "", noDataAvailableText, "", "", ""])
        }
        let fileName = language === 'EN'? 'battery_log_':'ব্যাটারী_লগ_'
        let presentTime = moment()
        exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
    }

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Smart Dongles"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Data log':'তথ্য লগ'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    <IconButton classes={classes}
                                                onClick={exportData}
                                                disabled={props.listLoading}>
                                        <img src={require('../../../../utils/asset/download-Button.svg')} alt="Download"/>
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'ID of the battery':'ব্যাটারী আইডি'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'batterySerial'}
                                                    type="text"
                                                    placeholder={language === 'EN'? 'Ex: 55001023':'উদাহরণঃ 55001023'}
                                                    value={batterySerial}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        clearAllErrorMessages()
                                                        if (e.target.value) {
                                                            let value = e.target.value;
                                                            setBatterySerial(value)
                                                            if (isNaN(value) || value.includes('.')) {
                                                                setInvalidBatterySerial(true)
                                                            } else {
                                                                setInvalidBatterySerial(false)
                                                            }
                                                        } else {
                                                            setBatterySerial("")
                                                        }
                                                    }}
                                                />
                                                {invalidBatterySerial? <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>
                                                    &nbsp;&nbsp;{language === 'EN'? 'Invalid battery serial!':'ভুল ' +
                                                    'ব্যাটারী সিরিয়াল!'}</div>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Dongle serial':'ডঙ্গল সিরিয়াল'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'dongleSerial'}
                                                    type="text"
                                                    placeholder={language === 'EN'? 'Ex: 44000001':'উদাহরণঃ 44000001'}
                                                    value={dongleSerial}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        clearAllErrorMessages()
                                                        if (e.target.value) {
                                                            let value = e.target.value;
                                                            setDongleSerial(value)
                                                            if (isNaN(value) || value.includes('.')) {
                                                                setInvalidDongleSerial(true)
                                                            } else {
                                                                setInvalidDongleSerial(false)
                                                            }
                                                        } else {
                                                            setDongleSerial("")
                                                        }
                                                    }}
                                                />
                                                {invalidDongleSerial? <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>
                                                    &nbsp;&nbsp;{language === 'EN'? 'Invalid Dongle serial!':'ভুল ' +
                                                    'ডঙ্গল সিরিয়াল!'}</div>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Time':'সময়'}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'timeSelection'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={"Select a date range"}
                                                    onClick={(e) => {
                                                        setTargetForTimeSelectionDateRangePicker(e.target)
                                                        setShowTimeSelectionDateRangePicker(!showTimeSelectionDateRangePicker)
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Capacity':'সক্ষমতা'}</Form.Label>
                                                </div>
                                                <Select
                                                    name={`capacity`}
                                                    placeholder={'Select capacity in Ah'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={selectedCapacity}
                                                    isClearable={true}
                                                    options={capacities}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        clearAllErrorMessages()
                                                        if (action === "clear") {
                                                            setSelectedCapacity("")
                                                        }
                                                        if (selected) {
                                                            setSelectedCapacity(selected)
                                                        } else {
                                                            setSelectedCapacity("")
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Voltage':'ভোল্টেজ'}</Form.Label>
                                                </div>
                                                <Select
                                                    name={`voltage`}
                                                    placeholder={'Select Voltage in V'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={selectedVoltage}
                                                    isClearable={true}
                                                    options={voltages}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        clearAllErrorMessages()
                                                        if (action === "clear") {
                                                            setSelectedVoltage("")
                                                        }
                                                        if (selected) {
                                                            setSelectedVoltage(selected)
                                                        } else {
                                                            setSelectedVoltage("")
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="warning" size={'sm'} type="button"
                                                    disabled={props.listLoading}
                                                    onClick={()=> {filterTheList()}}>
                                                {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                            </Button>
                                            <Button variant="outline-dark" size={'sm'} disabled={props.listLoading}
                                                    onClick={()=> {resetFilter()}} className={'ml-3'}>
                                                {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                            </Button>
                                        </div>
                                        {requiredErrorMessage? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>
                                            &nbsp;&nbsp;{generalRequiredMessage}</div>:null}
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন তথ্য নেই'}
                                columns={batteryLogColumns}
                                data={batteryLog}
                                showToolbar={false}
                                asyncPagination={!!props.batteryLog?.count}
                                isLoading={props.tableLoading}
                                pagination={!props.batteryLog?.count}
                                pageSize={props.batteryLog?.count? 0:10}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                // actions={checkAuthorization(authorization, deviceServiceName, deviceFeatureName, [updateActionName])? [{}]:[]}
                                actions={[{}]}
                                actionButtonVariant={['warning']}
                                actionButtonSize={'sm'}
                                actionButtonClickEvent={[viewBatteryLog]}
                                actionButtonText={language === 'EN'? ['View graph']:['গ্রাফ দেখুন']}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting battery log ..': 'ব্যাটারী লগ প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {timeSelectionPopover()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

BatteryLogs.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryLogReducer.isLoading,
        tableLoading: state.batteryLogReducer.tableLoading,
        statusCode: state.batteryLogReducer.statusCode,
        batteryLogs: state.batteryLogReducer.batteryLogs,
        filterTriggeredLoading: state.batteryLogReducer.filterTriggeredLoading,
        listLoading: state.batteryLogReducer.listLoading,
        iconLoading: state.batteryLogReducer.iconLoading
    }
}

export default connect(mapStateToProps, actions)(withRoles(BatteryLogs));
