import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    COLLECT_TOTAL_PAYMENT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/payment', filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_TOTAL_PAYMENT_REQUEST,
                actionTypes.COLLECT_TOTAL_PAYMENT_SUCCESS,
                actionTypes.COLLECT_TOTAL_PAYMENT_FAILURE
            ]
        }
    }),

    COLLECT_RENT_LIST_V1: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/rents', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_RENT_LIST_V1_REQUEST,
                actionTypes.COLLECT_RENT_LIST_V1_SUCCESS,
                actionTypes.COLLECT_RENT_LIST_V1_FAILURE
            ]
        }
    }),

    COLLECT_RENT_LIST_V3: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/rents', filter),
            method: 'GET',
            backend_slug: 'garage_management_v3',
            types: [
                actionTypes.COLLECT_RENT_LIST_V3_REQUEST,
                actionTypes.COLLECT_RENT_LIST_V3_SUCCESS,
                actionTypes.COLLECT_RENT_LIST_V3_FAILURE
            ]
        }
    }),

    GET_RENT_LIST_TO_EXPORT_V1: (filter)=>({
        [CALL_API]: {
            endpoint: typeof filter === 'object'? paramsHandler('/rents', filter): '/rents',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_RENT_LIST_TO_EXPORT_V1_REQUEST,
                actionTypes.GET_RENT_LIST_TO_EXPORT_V1_SUCCESS,
                actionTypes.GET_RENT_LIST_TO_EXPORT_V1_FAILURE
            ]
        }
    }),

    GET_RENT_LIST_TO_EXPORT_V3: (filter)=>({
        [CALL_API]: {
            endpoint: typeof filter === 'object'? paramsHandler('/rents', filter): '/rents',
            method: 'GET',
            backend_slug: 'garage_management_v3',
            types: [
                actionTypes.GET_RENT_LIST_TO_EXPORT_V3_REQUEST,
                actionTypes.GET_RENT_LIST_TO_EXPORT_V3_SUCCESS,
                actionTypes.GET_RENT_LIST_TO_EXPORT_V3_FAILURE
            ]
        }
    }),

    GET_RENT_LIST_TO_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: typeof filter === 'object'? paramsHandler('/rents', filter): '/rents',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_RENT_LIST_TO_EXPORT_REQUEST,
                actionTypes.GET_RENT_LIST_TO_EXPORT_SUCCESS,
                actionTypes.GET_RENT_LIST_TO_EXPORT_FAILURE
            ]
        }
    }),

    VERIFY_DRIVER: (phoneNumber)=>({
        [CALL_API]: {
            endpoint: `/drivers/` + phoneNumber,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.VERIFY_DRIVER_REQUEST,
                actionTypes.VERIFY_DRIVER_SUCCESS,
                actionTypes.VERIFY_DRIVER_FAILURE,
            ]
        }
    }),

    VERIFY_DEVICE: (serialNumber)=>({
        [CALL_API]: {
            endpoint: `/devices/` + serialNumber,
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.VERIFY_DEVICE_REQUEST,
                actionTypes.VERIFY_DEVICE_SUCCESS,
                actionTypes.VERIFY_DEVICE_FAILURE,
            ]
        }
    }),

    CHECKOUT_DRIVER: (payload)=>({
        [CALL_API]: {
            endpoint: '/drivers/check-out',
            method: 'POST',
            body : payload,
            backend_slug: 'garage_management',
            types: [
                actionTypes.CHECKOUT_DRIVER_REQUEST,
                actionTypes.CHECKOUT_DRIVER_SUCCESS,
                actionTypes.CHECKOUT_DRIVER_FAILURE,
            ]
        }
    }),

    COLLECT_GARAGE_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/garages',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_LIST_REQUEST,
                actionTypes.COLLECT_GARAGE_LIST_SUCCESS,
                actionTypes.COLLECT_GARAGE_LIST_FAILURE,
            ]
        }
    }),

    COLLECT_DRIVER_DETAILS: (phoneNumber)=>({
        [CALL_API]: {
            endpoint: '/drivers/' + phoneNumber,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_DRIVER_DETAILS_REQUEST,
                actionTypes.COLLECT_DRIVER_DETAILS_SUCCESS,
                actionTypes.COLLECT_DRIVER_DETAILS_FAILURE,
            ]
        }
    }),

    COLLECT_EV_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/ev', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_EV_LIST_REQUEST,
                actionTypes.COLLECT_EV_LIST_SUCCESS,
                actionTypes.COLLECT_EV_LIST_FAILURE
            ]
        }
    }),

    COLLECT_INACTIVE_BATTERY_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/devices/inactive', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_INACTIVE_BATTERY_LIST_REQUEST,
                actionTypes.COLLECT_INACTIVE_BATTERY_LIST_SUCCESS,
                actionTypes.COLLECT_INACTIVE_BATTERY_LIST_FAILURE
            ]
        }
    }),

    COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY: (deviceSerial, filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/rent/active_days_summary/' + deviceSerial, filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_REQUEST,
                actionTypes.COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_SUCCESS,
                actionTypes.COLLECT_ACTIVITY_DETAILS_OF_A_SMART_BATTERY_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE}),

    RETURN_TO_INITIAL_STATE_FOR_DRIVER_DETAILS_MODAL: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE_FOR_DRIVER_DETAILS_MODAL})
};
